var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "fill-height text-center" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c("h1", { staticClass: "text-h3 primary--text mb-4" }, [
              _vm._v("Whoops..."),
            ]),
            _c("p", { staticClass: "ma-0" }, [
              _vm._v(
                "It looks like you don't have any account set on your profile."
              ),
            ]),
            _c("p", [_vm._v("Please contact your admin.")]),
          ]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            bottom: "",
            right: "",
            color: "blue",
            dark: "",
            fixed: "",
          },
          on: { click: _vm.logoutUser },
        },
        [_c("v-icon", [_vm._v("logout")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }