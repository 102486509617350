
import { Getter } from 'vuex-class';
import { iframeResizer } from 'iframe-resizer';
import { Component, Vue } from 'vue-property-decorator';
import jwt from 'jsonwebtoken';
import AnnounceKit from 'announcekit-vue';

@Component({
	components: { AnnounceKit },
})
export default class Home extends Vue {
	@Getter('filters/filteredAccounts') filteredAccounts!: Account[];

	iframeUrl = '';

	mounted() {
		const METABASE_SITE_URL = 'https://fashiondata.metabaseapp.com';
		const mtbD2 = {
			resource: { dashboard: 34 },
			params: { account: this.filteredAccounts[0].id },
			exp: Math.round(Date.now() / 1000) + 60 * 60, // 1 hour expiration
		};
		const token = jwt.sign(mtbD2, process.env.VUE_APP_METABASE_SECRET_KEY as string);

		this.iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}#theme=light&bordered=false&titled=false&refresh=60`;
		this.$nextTick(() => {
			iframeResizer({}, this.$refs.iframe as HTMLElement);
		});
	}
}
