var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dense: "",
            color: "primary",
            dark: "",
            extended: "",
            "extension-height": "300",
            text: "",
          },
        },
        [
          _c(
            "v-toolbar-title",
            {
              staticClass: "mx-auto text-center",
              attrs: { slot: "extension" },
              slot: "extension",
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.title))]),
              _c("span", [_vm._v(_vm._s(_vm.subTitle))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }