
import { Component, Vue } from 'vue-property-decorator';
import { LOGOUT } from '@/constants/router/routes-names';

@Component
export default class NoAccount extends Vue {
	logoutUser() {
		this.$router.push({ name: LOGOUT });
	}
}
