var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        _vm._l(_vm.links, function (item) {
          return _c(
            "v-col",
            { key: item.id, attrs: { cols: "12", lg: "5", sm: "6" } },
            [
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ hover }) {
                        return _c(
                          "v-card",
                          { class: `elevation-${hover ? 12 : 2} rounded-card` },
                          [
                            _c("v-img", {
                              class: item.color,
                              attrs: {
                                src: "",
                                "aspect-ratio": "2.75",
                                height: "25px",
                              },
                            }),
                            _c(
                              "v-card-title",
                              { attrs: { "primary-title": "" } },
                              [
                                _c("h3", { staticClass: "text-h5 mb-0" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "mb-0 text-subtitle-1" },
                                  [_vm._v(_vm._s(item.description))]
                                ),
                              ]
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "learn-more-btn",
                                    attrs: { text: "", color: "primary" },
                                  },
                                  [_vm._v("Learn more")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  { attrs: { text: "", color: "primary" } },
                                  [_vm._v("Get started")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }