var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "home-box" }, [
        _c("div", { staticClass: "left-box" }, [
          _c(
            "div",
            { staticClass: "text-box" },
            [
              _vm._m(0),
              _c(
                "v-btn",
                {
                  staticClass: "button mt-auto mb-10 white--text",
                  attrs: {
                    width: "260px",
                    color: "#1D2129",
                    href: "https://docs.tailer.ai/",
                    target: "_blank",
                    rounded: "",
                  },
                },
                [_vm._v("How to use Tailer STUDIO")]
              ),
            ],
            1
          ),
          _c("img", {
            staticClass: "illustration",
            attrs: {
              src: require("@/assets/img/app/home-illu.svg"),
              alt: "Tailer Logo",
            },
          }),
        ]),
        _c("div", { staticClass: "right-part" }, [
          _c(
            "div",
            { staticClass: "box box-1" },
            [
              _vm._m(1),
              _c(
                "v-btn",
                {
                  staticClass: "mt-auto white--text button ak-trigger",
                  attrs: {
                    rounded: "",
                    width: "110px",
                    color: "#6652A6",
                    href: "#",
                  },
                },
                [_vm._v(" Discover ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box box-2" },
            [
              _vm._m(2),
              _c(
                "v-btn",
                {
                  staticClass: "mt-auto white--text button",
                  attrs: {
                    href: "https://fashiondata.statuspage.io/",
                    target: "_blank",
                    width: "110px",
                    rounded: "",
                    color: "#6652A6",
                  },
                },
                [_vm._v("Check")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "iframe-wrapper" }, [
        _c("iframe", {
          ref: "iframe",
          staticClass: "iframe",
          attrs: {
            src: _vm.iframeUrl,
            frameborder: "0",
            width: "100%",
            allowtransparency: "",
          },
        }),
      ]),
      _c("AnnounceKit", {
        attrs: {
          catchClick: ".ak-trigger",
          widget: "https://announcekit.app/widgets/v2/Ydr0Y",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h2", { staticClass: "main-title" }, [
        _vm._v(" Hey there,"),
        _c("br"),
        _vm._v(" happy to see you again "),
      ]),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          " Tailer is primed to guide and accelerate your journey in leveraging data for optimal retail decisions. Let’s begin. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("p", { staticClass: "main-title" }, [_vm._v("What’s new")]),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "Dive into the latest features and enhancements that have just been rolled out on Tailer."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("p", { staticClass: "main-title" }, [_vm._v("Status")]),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "Review the operational status of Tailer’s various components in case of any malfunction."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }